.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  box-sizing: border-box;
  background-color: #174195;
  cursor: pointer;
  color: #fff;
  padding: 12px 20px;
  -webkit-appearance: none;
  border-radius: 0;
  font-size: 16px;
  line-height: 1.3125;
  text-align: center;
  text-decoration: none;
  font-weight: normal;
  font-family: 'MaisonNeue-Bold', helvetica, sans-serif;
  transition: background 250ms;
}
.button:hover,
.button:focus {
  color: #fff;
  background-color: #182B5E;
}
.button:active {
  background-color: #182B5E;
}
.togglenavigation {
  right: 85px;
}
@media only screen and (min-width: 1920px) {
  .home {
    margin-left: 0;
  }
  .navbar .desk,
  .section--footer-top .desk,
  .section--footer .desk {
    width: 100%;
    max-width: calc(100% - 170px);
  }
}
.cb-layout1 .mood .downlink {
  left: -64px;
  margin-left: 0;
}
.cb-layout6 .immo-toplink .cb-goto-top {
  width: auto;
  height: 40px;
  padding: 10px 15px 10px 30px;
  background-position: left 15px top 14px;
  background-size: 10px 10px;
}
.cb-layout1 .area.side h2 {
  font-size: 32px;
  line-height: 1.1875;
}
h2 {
  font-size: 20px;
  line-height: 1.25;
}
.cb-layout5 .area.main {
  width: 100%;
}
.cb-layout5 .area.main > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-layout5 .area.main .cb-album .body,
.cb-layout5 .area.main .head,
.cb-layout5 .area.main .foot,
.cb-layout5 .area.main .part {
  margin-right: 4.76190476%;
  margin-left: 4.76190476%;
  width: 90.47619048%;
}
.cb-layout5 .area.main .tiny {
  width: 40.47619048%;
}
.cb-layout5 .area.main > .slim .part {
  width: 90.47619048%;
}
.cb-layout5 .area.main > .slim .tiny {
  width: 40.47619048%;
}
.cb-layout1 .area.base,
.cb-layout2 .area.base {
  width: 100%;
}
.cb-layout1 .area.base > .unit,
.cb-layout2 .area.base > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-layout1 .area.base .cb-album .body,
.cb-layout2 .area.base .cb-album .body,
.cb-layout1 .area.base .head,
.cb-layout2 .area.base .head,
.cb-layout1 .area.base .foot,
.cb-layout2 .area.base .foot,
.cb-layout1 .area.base .part,
.cb-layout2 .area.base .part {
  margin-right: 2.38095238%;
  margin-left: 2.38095238%;
  width: 95.23809524%;
}
.cb-layout1 .area.base .tiny,
.cb-layout2 .area.base .tiny {
  width: 45.23809524%;
}
.cb-layout1 .area.base > .slim,
.cb-layout2 .area.base > .slim {
  width: 50%;
}
.cb-layout1 .area.base > .slim .head,
.cb-layout2 .area.base > .slim .head,
.cb-layout1 .area.base > .slim .foot,
.cb-layout2 .area.base > .slim .foot,
.cb-layout1 .area.base > .slim .part,
.cb-layout2 .area.base > .slim .part {
  margin-right: 4.76190476%;
  margin-left: 4.76190476%;
  width: 90.47619048%;
}
.cb-layout1 .area.base > .slim.cb-album .body,
.cb-layout2 .area.base > .slim.cb-album .body {
  margin-right: 4.76190476%;
  margin-left: 4.76190476%;
  width: 90.47619048%;
}
.cb-layout1 .area.base > .slim .tiny,
.cb-layout2 .area.base > .slim .tiny {
  width: 40.47619048%;
}
.cb-layout3 .area.main,
.cb-layout4 .area.main,
.cb-layout4 .area.base {
  width: 105%;
  margin-left: -2.5%;
}
.cb-layout3 .area.main .unit.pure.wide,
.cb-layout4 .area.main .unit.pure.wide,
.cb-layout4 .area.base .unit.pure.wide {
  width: 66.66666667%;
  margin-left: 16.66666667%;
  margin-right: 16.66666667%;
}
.cb-layout3 .area.main .unit.pure.wide .part,
.cb-layout4 .area.main .unit.pure.wide .part,
.cb-layout4 .area.base .unit.pure.wide .part {
  width: 92.85714286%;
  margin-left: 3.57142857%;
  margin-right: 3.57142857%;
}
.cb-layout3 .area.main .unit.pure.wide .part.tiny,
.cb-layout4 .area.main .unit.pure.wide .part.tiny,
.cb-layout4 .area.base .unit.pure.wide .part.tiny {
  width: 42.85714286%;
}
.cb-layout3 .area.main .unit.pure.slim,
.cb-layout4 .area.main .unit.pure.slim,
.cb-layout4 .area.base .unit.pure.slim {
  width: 33.33333333%;
}
.cb-layout3 .area.main .unit.pure.slim .part,
.cb-layout4 .area.main .unit.pure.slim .part,
.cb-layout4 .area.base .unit.pure.slim .part {
  width: 85.71428571%;
  margin-left: 7.14285714%;
  margin-right: 7.14285714%;
}
.cb-layout3 .area.main .unit.pure.slim .part.tiny,
.cb-layout4 .area.main .unit.pure.slim .part.tiny,
.cb-layout4 .area.base .unit.pure.slim .part.tiny {
  width: 35.71428571%;
}
.cb-layout3 .area.main .unit.seam.wide,
.cb-layout4 .area.main .unit.seam.wide,
.cb-layout4 .area.base .unit.seam.wide {
  width: 66.66666667%;
  margin-left: 16.66666667%;
  margin-right: 16.66666667%;
  padding-right: 16.66666667%;
}
.cb-layout3 .area.main .unit.seam.wide .part,
.cb-layout4 .area.main .unit.seam.wide .part,
.cb-layout4 .area.base .unit.seam.wide .part {
  width: 90.47619048%;
  margin-left: 4.76190476%;
  margin-right: 4.76190476%;
}
.cb-layout3 .area.main .unit.seam.wide .part.tiny,
.cb-layout4 .area.main .unit.seam.wide .part.tiny,
.cb-layout4 .area.base .unit.seam.wide .part.tiny {
  width: 40.47619048%;
}
.cb-layout3 .area.main .unit.seam.slim,
.cb-layout4 .area.main .unit.seam.slim,
.cb-layout4 .area.base .unit.seam.slim {
  width: 50%;
}
.cb-layout3 .area.main .unit.seam.slim .part,
.cb-layout4 .area.main .unit.seam.slim .part,
.cb-layout4 .area.base .unit.seam.slim .part {
  width: 90.47619048%;
  margin-left: 4.76190476%;
  margin-right: 4.76190476%;
}
.cb-layout3 .area.main .unit.seam.slim .part.tiny,
.cb-layout4 .area.main .unit.seam.slim .part.tiny,
.cb-layout4 .area.base .unit.seam.slim .part.tiny {
  width: 40.47619048%;
}
.cb-layout5 .area.main {
  width: 52.5%;
  margin-left: -2.5%;
}
.cb-layout1 .area.side {
  width: 111.5%;
  margin-left: -5.75%;
}
.cb-layout1 .area.side .unit {
  width: 23.01943199%;
  margin-left: 5.15695067%;
  margin-right: 5.15695067%;
}
.cb-layout4 .area.side {
  width: 73.75%;
}
.cb-layout5 .area.side {
  width: 38.75%;
}
.cb-layout1 .area.base,
.cb-layout2 .area.base {
  width: 105%;
  margin-left: -2.5%;
}
.cb-layout1 .area.base .unit.seam,
.cb-layout2 .area.base .unit.seam {
  width: 95.23809524%;
  margin-left: 2.38095238%;
  margin-right: 2.38095238%;
}
.cb-layout1 .area.base .unit.seam.slim,
.cb-layout2 .area.base .unit.seam.slim {
  width: 45.23809524%;
}
.cb-layout1 .area.base .unit.seam .body,
.cb-layout2 .area.base .unit.seam .body {
  width: 71.92982456%;
  margin-left: 28.07017544%;
  padding-top: 44.21052632%;
}
.cb-layout1 .area.base .unit.seam .part.pict,
.cb-layout2 .area.base .unit.seam .part.pict {
  width: 139.02439024%;
  border-right-width: 40px;
}
.cb-layout1 .area.base .unit.seam .part:not(.pict),
.cb-layout2 .area.base .unit.seam .part:not(.pict) {
  padding: 10px 30px 5px 40px;
}
.cb-layout1 .area.base .unit.seam .part:not(.pict):nth-child(2),
.cb-layout2 .area.base .unit.seam .part:not(.pict):nth-child(2) {
  padding-top: 30px;
}
.cb-layout1 .area.base .unit.seam .part:not(.pict):last-child,
.cb-layout2 .area.base .unit.seam .part:not(.pict):last-child {
  padding-bottom: 40px;
}
.cb-layout6 .area.base .unit:not(:first-child),
.cb-layout6 .area.base .unit.unit--immo-result {
  width: 82.5%;
  margin-left: 8.75%;
  margin-right: 8.75%;
}
.cb-layout6 .area.base .unit:not(:first-child) .part.pict,
.cb-layout6 .area.base .unit.unit--immo-result .part.pict {
  width: 38.38383838%;
}
.cb-layout6 .area.base .unit:not(:first-child) .part.part--immo-text,
.cb-layout6 .area.base .unit.unit--immo-result .part.part--immo-text,
.cb-layout6 .area.base .unit:not(:first-child) .part.code,
.cb-layout6 .area.base .unit.unit--immo-result .part.code {
  float: right;
  width: 57.57575758%;
}
.cb-layout1 .flat div.link {
  padding-right: 40px;
}
.cb-layout1 .flat div.link a.open {
  padding-top: 5px;
}
/*# sourceMappingURL=./screen-large.css.map */